<template>
  <b-modal
    id="modal-active-menu"
    hideHeaderClose
    no-close-on-backdrop
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    size="lg"
    centered
  >
    <b-row>
      <b-col cols="12" class="modalTitle">
        <h2 class="font-weight-bolder">
          {{ $t("settings.config_menu") }}
        </h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group :label="$t('menu_name.stand')">
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            switch
            v-model="menu.stand"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group :label="$t('menu_name.totem')">
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            switch
            v-model="menu.totem"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group :label="$t('menu_name.meeting_point')">
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            switch
            v-model="menu.meeting_point"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group :label="$t('menu_name.conference')">
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            switch
            v-model="menu.conference"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group :label="$t('menu_name.users')">
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            switch
            v-model="menu.users"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group :label="$t('menu_name.personalization')">
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            switch
            v-model="menu.personalization"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group :label="$t('menu_name.notication')">
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            switch
            v-model="menu.notication"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group :label="$t('menu_name.editor')">
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            switch
            v-model="menu.editor"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group :label="$t('menu_name.statistics')">
          <b-form-checkbox
            checked="true"
            class="custom-control-primary"
            switch
            v-model="menu.statistics"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BModal,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  props: ["menu"],
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BModal,
    BFormCheckbox,
  },
};
</script>
