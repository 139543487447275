var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-firebase","hideHeaderClose":"","no-close-on-backdrop":"","ok-title":_vm.$t('Save'),"cancel-title":_vm.$t('Abort'),"size":"lg","centered":"","hide-footer":""}},[_c('b-row',[_c('b-col',{staticClass:"modalTitle",attrs:{"cols":"12"}},[_c('h2',{staticClass:"font-weight-bolder"},[_vm._v(" Firebase config ")])])],1),_c('validation-observer',{ref:"firebase",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Api Key *","label-for":"apiKey"}},[_c('validation-provider',{attrs:{"rules":"required","name":"nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"apiKey","placeholder":"apiKey"},model:{value:(_vm.firebase_config.apiKey),callback:function ($$v) {_vm.$set(_vm.firebase_config, "apiKey", $$v)},expression:"firebase_config.apiKey"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Auth Domain *","label-for":"authDomain"}},[_c('validation-provider',{attrs:{"rules":"required","name":"nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"authDomain","placeholder":"authDomain"},model:{value:(_vm.firebase_config.authDomain),callback:function ($$v) {_vm.$set(_vm.firebase_config, "authDomain", $$v)},expression:"firebase_config.authDomain"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Project Id *","label-for":"projectId"}},[_c('validation-provider',{attrs:{"rules":"required","name":"nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"projectId","placeholder":"projectId"},model:{value:(_vm.firebase_config.projectId),callback:function ($$v) {_vm.$set(_vm.firebase_config, "projectId", $$v)},expression:"firebase_config.projectId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Storage Bucket *","label-for":"storageBucket"}},[_c('validation-provider',{attrs:{"rules":"required","name":"nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"storageBucket","placeholder":"storageBucket"},model:{value:(_vm.firebase_config.storageBucket),callback:function ($$v) {_vm.$set(_vm.firebase_config, "storageBucket", $$v)},expression:"firebase_config.storageBucket"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Messaging Sender Id *","label-for":"messagingSenderId"}},[_c('validation-provider',{attrs:{"rules":"required","name":"nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"messagingSenderId","placeholder":"messagingSenderId"},model:{value:(_vm.firebase_config.messagingSenderId),callback:function ($$v) {_vm.$set(_vm.firebase_config, "messagingSenderId", $$v)},expression:"firebase_config.messagingSenderId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"App Id *","label-for":"appId"}},[_c('validation-provider',{attrs:{"rules":"required","name":"nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"appId","placeholder":"appId"},model:{value:(_vm.firebase_config.appId),callback:function ($$v) {_vm.$set(_vm.firebase_config, "appId", $$v)},expression:"firebase_config.appId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Measurement Id *","label-for":"measurementId"}},[_c('validation-provider',{attrs:{"rules":"required","name":"nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"measurementId","placeholder":"measurementId"},model:{value:(_vm.firebase_config.measurementId),callback:function ($$v) {_vm.$set(_vm.firebase_config, "measurementId", $$v)},expression:"firebase_config.measurementId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Client Id *","label-for":"clientId"}},[_c('validation-provider',{attrs:{"rules":"required","name":"nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"clientId","placeholder":"clientId"},model:{value:(_vm.firebase_config.clientId),callback:function ($$v) {_vm.$set(_vm.firebase_config, "clientId", $$v)},expression:"firebase_config.clientId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Property Id *","label-for":"propertyId"}},[_c('validation-provider',{attrs:{"rules":"required","name":"nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"propertyId","placeholder":"propertyId"},model:{value:(_vm.firebase_config.propertyId),callback:function ($$v) {_vm.$set(_vm.firebase_config, "propertyId", $$v)},expression:"firebase_config.propertyId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticStyle:{"justify-content":"flex-end"}},[_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"block":"","variant":"danger"},on:{"click":_vm.close}},[_vm._v(" Chiudi ")])],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"block":"","variant":"primary","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.updateData.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("save_edit"))+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }