<template>
  <b-modal
    id="modal-firebase"
    hideHeaderClose
    no-close-on-backdrop
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    size="lg"
    centered
    hide-footer
  >
    <b-row>
      <b-col cols="12" class="modalTitle">
        <h2 class="font-weight-bolder">
          Firebase config
        </h2>
      </b-col>
    </b-row>
    <validation-observer v-slot="{ invalid }" ref="firebase">
      <b-row>
        <b-col cols="12">
          <b-form-group :label="`Api Key *`" label-for="apiKey">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="nome"
            >
              <b-form-input
                id="apiKey"
                placeholder="apiKey"
                v-model="firebase_config.apiKey"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group :label="`Auth Domain *`" label-for="authDomain">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="nome"
            >
              <b-form-input
                id="authDomain"
                placeholder="authDomain"
                v-model="firebase_config.authDomain"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group :label="`Project Id *`" label-for="projectId">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="nome"
            >
              <b-form-input
                id="projectId"
                placeholder="projectId"
                v-model="firebase_config.projectId"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group :label="`Storage Bucket *`" label-for="storageBucket">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="nome"
            >
              <b-form-input
                id="storageBucket"
                placeholder="storageBucket"
                v-model="firebase_config.storageBucket"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            :label="`Messaging Sender Id *`"
            label-for="messagingSenderId"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="nome"
            >
              <b-form-input
                id="messagingSenderId"
                placeholder="messagingSenderId"
                v-model="firebase_config.messagingSenderId"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group :label="`App Id *`" label-for="appId">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="nome"
            >
              <b-form-input
                id="appId"
                placeholder="appId"
                v-model="firebase_config.appId"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group :label="`Measurement Id *`" label-for="measurementId">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="nome"
            >
              <b-form-input
                id="measurementId"
                placeholder="measurementId"
                v-model="firebase_config.measurementId"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group :label="`Client Id *`" label-for="clientId">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="nome"
            >
              <b-form-input
                id="clientId"
                placeholder="clientId"
                v-model="firebase_config.clientId"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group :label="`Property Id *`" label-for="propertyId">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="nome"
            >
              <b-form-input
                id="propertyId"
                placeholder="propertyId"
                v-model="firebase_config.propertyId"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row style="justify-content: flex-end;">
        <b-col md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="danger"
            class="mt-2"
            @click="close"
          >
            Chiudi
          </b-button>
        </b-col>
        <b-col md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            class="mt-2"
            @click.prevent="updateData"
            :disabled="invalid"
          >
            {{ $t("save_edit") }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BModal,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";

export default {
  props: ["firebase_config"],
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  methods: {
    updateData() {
      const $this = this;
      this.$refs.firebase.validate().then((success) => {
        if (success) {
          this.$bvModal.hide("modal-firebase");
        }
      });
      return false;
    },
    close() {
      this.$bvModal.hide("modal-firebase");
    },
  },
};
</script>
